











import { Component, Vue } from 'vue-property-decorator'
import { AnonymousInvestorPlatformClient } from '@/client'

@Component({})
export default class ConfirmRegistration extends Vue {
  isRegistrationSuccessful: boolean = false

  async mounted() {
    const client = new AnonymousInvestorPlatformClient(process.env.VUE_APP_API_ENDPOINT!)
    await client.confirmRegistration(this.$route.query.token as string)
    this.isRegistrationSuccessful = true
  }
}
